import { PipeTransform, Pipe } from "@angular/core";

@Pipe({
  name: "shorten_guid",
})
export class GuidPipe implements PipeTransform {
  transform(value: string): string {
    return value ? value.slice(0, 8) : "";
  }
}
